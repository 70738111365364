<template>
  <el-dialog
    :title="dataForm.id ? '编辑' : '新增' + '围餐套餐详情'"
    :close-on-click-modal="false"
    :visible.sync="visible"
    class="missionPart"
    append-to-body
  >
    <el-form :model="dataForm" ref="dataForm" label-width="80px">
      <div class="tablePart">
        <el-form-item label="套餐名" prop="name">
          <el-input v-model="dataForm.name" clearable />
        </el-form-item>
        <el-form-item label="原价" prop="price">
          <el-input-number v-model="dataForm.price" :min="0" />
        </el-form-item>
        <el-form-item label="折扣价" prop="discountPrice">
          <el-input-number v-model="dataForm.discountPrice" :min="0" />
        </el-form-item>
        <el-form-item label="是否使用" prop="isUse">
          <el-radio-group v-model="dataForm.isUse">
            <el-radio :label="1">使用中</el-radio>
            <el-radio :label="0">禁用中</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="可用日期" prop="useDate">
          <el-input
            v-model="dataForm.useDate"
            clearable
            placeholder="请填写可用日期(即星期一至星期日可用)这样的词语"
          />
        </el-form-item>
        <el-form-item label="限制提醒" prop="reminder" class="fullList">
          <el-input v-model="dataForm.reminder" rows="3" type="textarea" />
        </el-form-item>
        <el-form-item label="使用规则" prop="useRules" class="fullList">
          <div ref="editor" />
        </el-form-item>
      </div>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleCancel">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { dialogMixin, normal } from '@/mixins';
import Editor from 'wangeditor';
export default {
  mixins: [dialogMixin, normal],
  data() {
    return {
      dataForm: {
        id: null,
        name: null, // 套餐名
        isUse: 1,
        price: 0, // 原价
        discountPrice: 0, // 折扣价
        useDate: null, // 可用日期
        reminder: null, // 限制提醒
        useRules: null, // 使用规则
      },
      editor: null, //富文本编辑器
      editorContent: null, //富文本内容
    };
  },
  methods: {
    init(id) {
      this.$init({
        before: () => {
          this.dataForm.id = id;
          this.$nextTick(() => {
            this.editorInit();
          });
        },
        url: id ? `/cm/shoppingrecord/findDetail/${this.dataForm.id}` : null,
        after: (data) => {
          if (data?.code === 0) {
            this.dataForm = { ...data.shoppingDetail };
            this.editor.txt.html(data.shoppingDetail.useRules);
          }
        },
      });
    },
    handleCancel() {
      this.visible = false;
      if (this.editor) {
        this.editor.txt.clear();
      }
    },
    editorInit() {
      if (!this.editor) {
        this.editor = new Editor(this.$refs.editor);
        // 上传图片到服务器
        this.editor.customConfig.uploadFileName = 'files'; //设置文件上传的参数名称
        this.editor.customConfig.uploadImgServer =
          this.$http.BASE_URL +
          `/sys/oss/upload?token=${this.$cookie.get('token')}`; //设置上传文件的服务器路径
        this.editor.customConfig.uploadImgMaxSize = 10 * 1024 * 1024; // 将图片大小限制为 3M
        //自定义上传图片事件
        this.editor.customConfig.uploadImgHooks = {
          customInsert: function (insertImg, result) {
            const url = process.env.VUE_APP_UPLOAD_URL + result.data.url;
            insertImg(url);
          },
        };
        this.editor.customConfig.uploadImgParams = {
          token: this.$cookie.get('token'),
        };

        this.editor.customConfig.onchange = (html) => {
          this.editorContent = html;
        };
        this.editor.create();
      }
    },
    dataFormSubmit() {
      if (!this.editor.txt.html()) {
        this.$error('使用规则内容不能为空');
      } else {
        this.dataForm.useRules = this.editor.txt.html();
        this.visible = false;
      }
    },
  },
};
</script>
